.cproject-pres .description {
  margin-top: 20px;
  line-height: 24px;
}

.cproject-pres .title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media all and (min-width: 1025px) {
  .cproject-pres .description {
    line-height: 27px;
  }
}
