.website-pres {
  max-width: 230px;
  min-width: 230px;
  height: 370px;
}

.website-pres img {
  transform: rotate(-8deg);
  width: 380px;
  margin-left: -40px;
  margin-top: 10px;
}
