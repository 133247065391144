#work-and-projects {
  position: relative;
  padding: 40px 0;
  background-color: var(--color-dark-grey);
  color: var(--color-fake-white);
  font-size: var(--text-size-small);
}

#work-and-projects-section {
  position: absolute;
  top: -40px;
}

#work-and-projects a {
  color: var(--color-turquoise);
  text-decoration: none;
}

#work-and-projects .top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

#work-and-projects h2 {
  font-size: var(--text-size-large);
  font-weight: normal;
  color: var(--color-blue);
  margin-right: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
}

#work-and-projects .quick-link-freelance {
  width: 190px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  line-height: normal;
}

#work-and-projects .quick-link-freelance i {
  margin-right: 10px;
  color: var(--color-turquoise);
}

#work-and-projects .quick-link-freelance a {
  display: block;
}

#work-and-projects .boxes-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  align-content: center;
  flex-wrap: wrap;
}

#work-and-projects .web-dev-box {
  width: 295px;
  border-radius: var(--border-radius);
  padding: 15px;
  margin: 10px;
}

#work-and-projects .web-dev-box p {
  text-align: justify;
  line-height: 23px;
}

@media all and (min-width: 1025px) {
  #work-and-projects .web-dev-box p {
    line-height: 24px;
  }
}

#work-and-projects .web-dev-box img {
  margin: 0 auto 25px auto;
  width: 50%;
  display: block;
}

#work-and-projects .web-dev-box h3 {
  font-weight: normal;
  font-size: var(--text-size-medium);
  color: var(--color-blue);
  margin-bottom: 10px;
  text-align: center;
}

#work-and-projects .web-dev-box ul {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

#work-and-projects .web-dev-box ul i {
  margin: 0 10px;
  color: var(--color-faded-grey);
}

#work-and-projects .slider {
  width: 100%;
  overflow-x: auto;
  padding: 10px 0px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}

@media all and (min-width: 768px) {
  #work-and-projects h2 br {
    display: none;
  }
}

@media all and (min-width: 1025px) {
  #work-and-projects .quick-link-freelance {
    width: 240px;
  }
}

.pres-box {
  margin: 10px;
  font-size: var(--text-size-small);
  position: relative;
  background-color: var(--color-light-grey);
  border-radius: var(--border-radius);
  overflow: hidden;
}

.pres-box .texts {
  padding: 20px;
  position: relative;
}

.pres-box .type {
  color: var(--color-faded-grey);
}

.pres-box .texts h4 {
  font-size: var(--text-size-medium);
  font-weight: normal;
}

.pres-box .texts a {
  display: block;
  margin: 10px 0;
}
