* {
  margin: 0;
  padding: 0;
  font-family: var(--font-primary);
}

ul {
  list-style: none;
}

a {
  transition: color 0.3s ease;
}

i {
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
