#intro {
  position: relative;
  font-size: var(--text-size-smaller);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--color-dark-grey);
  color: var(--color-fake-white);
  padding: 60px 0;

  line-height: normal;
}

#intro-section {
  position: absolute;
  top: -60px;
}

/* #intro .icons-list {
  position: absolute;
  top: 10px;
  right: 5px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: var(--text-size-large);
} */

#intro img {
  width: 90px;
  border-radius: 50%;
}

#intro .right-text {
  margin-left: 4%;
  width: 255px;
}

#intro h1 {
  font-weight: normal;
  font-size: var(--text-size-large);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#intro ul {
  font-size: var(--text-size-smaller);
}

#intro ul li {
  margin-top: 10px;
}

#intro ul li i {
  width: 30px;
  border-radius: 50%;
  color: var(--color-turquoise);
}

@media all and (min-width: 768px) {
  #intro img {
    width: 120px;
  }

  #intro .right-text {
    width: auto;
    margin-left: 2%;
  }
}
