header {
  position: sticky;
  top: 0px;
  box-sizing: border-box;
  width: 100%;
  background-color: var(--color-light-grey);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
  padding: 15px 10px;
  font-size: var(--text-size-smaller);
  color: var(--color-fake-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
}

header .wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

header .wrapper .left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

header a {
  color: inherit;
  text-decoration: none;
}

header #logo {
  font-size: var(--text-size-medium);
}

header nav {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

header nav a {
  margin-left: 20px;
}

header nav a:hover {
  color: var(--color-turquoise);
}

header .icons-list {
  display: flex;
  flex-direction: row;
  align-items: center;
}
