#contact {
  position: relative;
  padding: 40px 0;
  background-color: var(--color-light-grey);
  color: var(--color-fake-white);
  font-size: var(--text-size-medium);
}

#contact-section {
  position: absolute;
  top: -40px;
}

.mail-form {
  margin: 0 auto;
  width: var(--wrapper-width-laptop);
}

.mail-form h2 {
  color: var(--color-blue);
  font-size: var(--text-size-large);
  font-weight: normal;
}

.mail-form p.descr {
  margin: 15px 0;
}

.mail-form .field-wrapper {
  width: 100%;
  margin-bottom: 20px;
}

.mail-form .field-wrapper.message {
  width: 100%;
}

.mail-form .field-wrapper.message textarea {
  height: 120px;
  width: 100%;
  resize: none;
  overflow-y: auto;
}

.mail-form .field-wrapper.message i {
  top: 10px;
  transform: none;
}

.mail-form .field-wrapper div {
  position: relative;
}

.mail-form .field-wrapper p {
  margin-bottom: 5px;
}

.mail-form .field-wrapper input,
.mail-form .field-wrapper textarea {
  background-color: var(--color-fake-white);
  width: 100%;
  height: 100%;
  display: block;
  padding: 10px;
  outline: none;
  border: none;
  border-radius: var(--border-radius);
  transition: outline 0.15s ease-out;
  color: var(--color-light-grey);
  font: inherit;
  box-sizing: border-box;
}

.mail-form .field-wrapper textarea:focus + i,
.mail-form .field-wrapper input:focus + i {
  top: -22px;
  transform: none;
}

.mail-form .field-wrapper input:focus,
.mail-form .field-wrapper textarea:focus {
  outline: 2px solid var(--color-blue);
}

.mail-form .field-wrapper i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
  color: var(--color-faded-grey);
  transition: top 0.2s ease-out;
}

.mail-form .required p::after {
  content: " *";
  color: var(--color-turquoise);
  font-size: 1.2m;
  font-weight: bold;
}

.mail-form .bttn-submit {
  position: relative;
  font-size: var(--text-size-medium);
  color: var(--color-fake-wihte);
  background-color: var(--color-blue);
  outline: none;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: var(--border-radius);
  transition: background-color 0.2s ease-in;
}
.mail-form .bttn-submit:hover {
  background-color: var(--color-dark-grey);
}

.g-recaptcha {
  transform: scale(0.53);
  transform-origin: 0 0;
  width: 180px;
}

@media all and (min-width: 768px) {
  .mail-form .field-wrapper {
    width: 47%;
  }
}
