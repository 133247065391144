@font-face {
  font-family: noto-sans;
  src: url("./Noto_Sans/NotoSans-Regular.ttf");
}

html {
  scroll-behavior: smooth;
}

section {
  line-height: 25px;
}
@media all and (min-width: 768px) {
  section {
    line-height: 27px;
  }
}

:root {
  --font-primary: noto-sans;
  --font-secondary: ubuntu;
  --text-size-smaller: 12px;
  --text-size-small: 14px;
  --text-size-medium: 16px;
  --text-size-large: 18px;

  --color-dark-grey: #1c2229;
  --color-light-grey: #232a33;
  --color-faded-grey: #799399;
  --color-blue: #00a6ff;
  --color-turquoise: #00eaff;
  --color-fake-white: #f0efeb;

  --wrapper-width-laptop: 90%;

  --border-radius: 3px;
}

.wrapper {
  width: var(--wrapper-width-laptop);
  margin: 0 auto;
  position: relative;
}

@media all and (min-width: 768px) {
  :root {
    --wrapper-width-laptop: 55%;
  }

  .wrapper {
    min-width: 740px;
    max-width: 850px;
  }
}

@media all and (min-width: 1025px) {
  :root {
    --text-size-smaller: 14px;
    --text-size-small: 16px;
    --text-size-medium: 18px;
    --text-size-large: 23px;
  }
}
