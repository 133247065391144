#academics {
  --left-div-width: 47%;

  font-size: var(--text-size-small);
  position: relative;
  padding: 40px 0;
  color: white;
  background-color: var(--color-light-grey);
  background-image: url("/images/icl.jpeg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 0 100%;
  box-sizing: border-box;
}

#academics p {
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
}

#academics-section {
  position: absolute;
  top: -40px;
}

#academics .wrapper {
  width: var(--wrapper-width-laptop);
  position: relative;
  margin: 0 auto;
}

#academics .text-photo-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

#academics .left-text {
  z-index: 1;
}

#academics h2 {
  font-size: var(--text-size-large);
  font-weight: normal;
  color: var(--color-blue);
  margin-bottom: 10px;
}

#academics img {
  display: none;
}

#academics .lists-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: center;

  position: relative;
  z-index: 1;
}

#academics .first-year-list {
  margin-right: 15px;
}

#academics ul li i {
  color: var(--color-turquoise);
  margin-right: 5px;
}

#academics .phone-gradient {
  width: 100%;
  max-height: 100%;
  height: calc(100vw * 0.76);
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgb(35, 42, 51);
  background: linear-gradient(
    167deg,
    rgba(35, 42, 51, 1) 35%,
    rgba(35, 42, 51, 0.8) 70%,
    rgba(35, 42, 51, 0.3) 100%
  );
}

@media all and (min-width: 768px) {
  #academics {
    background-image: none;
  }

  #academics .left-text {
    width: 47%;
  }

  #academics img {
    display: inline-block;
    width: var(--left-div-width);
    border-radius: var(--border-radius);
    margin-bottom: 15px;
  }

  #academics .second-year-list {
    width: var(--left-div-width);
  }

  #academics .phone-gradient {
    display: none;
  }
}
