footer {
  font-size: var(--text-size-smaller);
  padding: 40px 0;
  background-color: var(--color-dark-grey);
  color: var(--color-fake-white);
}

footer ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

footer ul li {
  margin: 5px 0;
}

footer .icons-list {
  font-size: var(--text-size-small);
  color: var(--color-faded-grey);
  justify-content: flex-start;
  margin-left: 10px;
}

footer .footer-text-link {
  color: var(--color-faded-grey);
  margin: 0 5px;
}

footer .data-priv-link {
  color: var(--color-faded-grey);
  text-decoration: underline;
  cursor: pointer;
}
