.starleaf-container h2 {
  font-size: var(--text-size-large);
}
.starleaf-container {
  font-size: var(--text-size-small);
}

.starleaf-container .sl-top-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

#work-and-projects #sl-title {
  margin-bottom: 0;
}

.starleaf-container .sl-proj {
  margin: 15px 0;
}

.starleaf-container .sl-proj h4 {
  color: var(--color-turquoise);
  font-weight: lighter;
}

.starleaf-container .sl-txt-img {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.starleaf-container .sl-txt-img img {
  display: none;
  border-radius: var(--border-radius);
  width: 45%;
}

@media all and (min-width: 768px) {
  .starleaf-container .sl-top-container {
    align-items: center;
  }
  .starleaf-container .sl-txt-img .txt {
    width: 47%;
  }

  .starleaf-container .sl-txt-img img {
    display: inline-block;
    width: 47%;
  }

  #work-and-projects #sl-title {
    margin-bottom: 20px;
  }

  .starleaf-container .sl-top-container {
    margin-bottom: 0;
  }
}
